import React from "react";
import { graphql } from "gatsby";
import Blogs from "../../components/Blogs";

const BlogsPL = (props) => <Blogs {...props} />;

export default BlogsPL;

export const pageQuery = graphql`
  query BlogsPLQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        frontmatter: { templateKey: { eq: "blog-post" }, draft: { ne: true } }
        fields: { langKey: { regex: "/(pl|any)/" } }
      }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            description
            templateKey
            date
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 600, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
